import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventMediator {
    // room changed event
    private _selectedRoomId$ = new BehaviorSubject<string>('');
    public roomIdChanged = this._selectedRoomId$.asObservable();

    private _isTimerDone$ = new BehaviorSubject<boolean>(false);
    public timerStateChanged = this._isTimerDone$.asObservable();

    private _isPathChange$ = new BehaviorSubject<string>('');
    public pathChange = this._isPathChange$.asObservable();

    public notifyOnRoomIdChanged(roomId: string): void {
        this._selectedRoomId$.next(roomId);
    }

    notifyCountdownTimerIsDone(isDone: boolean): void {
        // console.log('isDone: ', isDone);
        this._isTimerDone$.next(isDone);
    }

    notifyPathChanging(path: string): void {
        this._isPathChange$.next(path);
    }
}
